import { registerApplication, start } from 'single-spa'
import 'systemjs/dist/system'
import 'systemjs/dist/extras/amd'
import 'systemjs/dist/extras/named-exports'
import 'babel-polyfill'

registerApplication(
  'root',
  () => import('./index.singlespa.js'),
  () => true,
)

start()
